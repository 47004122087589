// header
.header {
	z-index: 900;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(#000, 0.35) 60%, rgba(#000, 0));
	transition: 1s ease;
	&:has(.navbar-toggler[aria-expanded="true"]) {
		background: $green !important;
		&::after {
			content: "";
			position: fixed;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: rgba(#000, 0.3);
			backdrop-filter: blur(3px);
			z-index: -1;
		}
	}
	&.sticky {
		box-shadow: $shadow;
		background: $white;
		transition: 1s ease;

		.column {
			@include media-breakpoint-down(lg) {
				align-items: center !important;
			}
		}

		.logo,
		.site-switcher {
			width: 120px;
			transition: 0.35s ease;
		}
		.logo {
			transform: rotate(10deg);
			@include media-breakpoint-down(lg) {
				transform: translateX(-50%);
				margin: 0 !important;
				top: 0;
			}
		}
		.site-switcher ul {
			flex-flow: row !important;
		}
		.navbar-nav .nav-item {
			.dropdown-menu.show {
				background-color: $white !important;
				transition: 0.35s ease;
			}
			.nav-link {
				color: $blue-dark !important;
				@include media-breakpoint-down(lg) {
					color: $white !important;
				}

				&:hover,
				&.active {
					text-decoration: underline 2px $blue-dark !important;
					@include media-breakpoint-down(lg) {
						text-decoration: underline 2px $white !important;
						text-underline-offset: 8px;
					}
				}
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				justify-content: center;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding: 15px 1.5rem;
					align-items: flex-start;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			background: $blue;
			color: $white;
			width: 40px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				color: #fff;
				font-size: 20px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
				transition: 0.5s ease;
			}
			&[aria-expanded="true"] {
				background: $white;
				i {
					color: $blue;
					transform: rotate3d(1, 1, 1, 360deg);
					transition: 0.5s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 210px;
		margin: 10px auto 10px 0;
		transition: 0.35s ease;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			position: absolute;
			left: 50%;
			top: 3vh;
			transform: translateX(-50%);
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 2;
			padding: 9vh 1rem;
			z-index: 900;
			.navbar-nav {
				.nav-item {
					.nav-link {
						color: #fff !important;
						font-family: $font-family-secondary;
						font-size: 18px;
					}
					.dropdown-menu.show {
						&::after,
						&::before {
							display: none;
						}
						background: none !important;
						box-shadow: none !important;
						border: 0 !important;
						padding: 0 !important;
						position: static !important;
						margin: 0 !important;
						transform: none !important;
						.nav-item .nav-link {
							color: $white;
							font-weight: 200;
							font-size: 16px;
							margin-left: 0.5rem;
							&::before {
								content: "- ";
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			max-width: fit-content;
			margin: auto;
			.navbar-nav {
				gap: 1.2rem;
				.nav-item {
					.nav-link {
						color: #fff;
						font-family: $font-family-primary;
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
					&.active,
					&:hover {
						text-decoration: underline 2px white;
						text-underline-offset: 8px;
					}
					.dropdown-menu.show {
						box-shadow: none;
						border: 0;
						min-width: 10rem;
						text-align: center;
						background-color: inherit;
						&::before,
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: auto;
		text-align: right;
		width: 200px;
		display: flex;
		justify-content: flex-end;
		ul {
			display: flex;
			flex-flow: column !important;
			gap: 0.65rem;
			li {
				margin: 0 !important;
			}
		}
	}
}
