// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption,
	.owl-dots,
	.owl-nav {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 55vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}

section.eyecatcher.large {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/eyecatcher-wave.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: 700;
		background-position: bottom;
	}
}

section.eyecatcher:not(.large) {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/eyecatcher-body-bg.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: 700;
		background-position: bottom;
	}
}
