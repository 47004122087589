// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home intro
.home-intro-section {
	background: url("/images/dot-blue-light.svg");
	background-repeat: no-repeat;
	background-size: 75%;
	background-position: center;
	position: relative;
	&::after {
		content: "";
		width: 100%;
		height: 6vh;
		position: absolute;
		bottom: calc(-6vh + 1px);
		left: 0;
		background: url("/images/wave-blue-light-bottom.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: top;

		z-index: 900;
	}
	.container {
		&.first {
			text-align: center;
			margin-bottom: 9vh;
			.title {
				color: $blue-dark !important;
			}
			.subtitle {
				color: $white !important;
			}
		}
		&.second {
			.grid {
				margin-bottom: calc(6vh + 90px);
				.item {
					@include media-breakpoint-down(lg) {
						padding-top: 12vh;
						&:nth-child(1) {
							padding-top: 0;
						}
					}
				}
				.card {
					border-radius: 14px;
					border: 1px solid #fff;
					overflow: visible;
					.card-image,
					.card-image img {
						border-radius: 14px;
					}
					.card-body {
						position: absolute;
						height: 100%;
						width: 100%;
						.card-caption {
							position: absolute;
							bottom: -87.5px;
							left: 50%;
							transform: translateX(-50%);
							background: $blue-dark;
							border: 5px solid $white;
							width: 175px;
							aspect-ratio: 1/1;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 15px;
							transition: 0.35s ease;
							.card-title {
								color: #fff;
								text-align: center;
								font-family: $font-family-secondary;
								font-size: 24px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px;
								margin-bottom: 0 !important;
							}
							.card-subtitle,
							.card-description {
								display: none;
							}
						}
					}
					&:hover {
						.card-caption {
							transform: translateX(-50%) rotate(5deg) scale(1.2);
							transition: 0.35s ease;
						}
					}
				}
			}
		}
		&.third {
			div.read-more {
				text-align: center;

				a.read-more {
					margin: auto;
					@extend .btn, .btn-outline-secondary;
					color: $white;
					border-color: $white;

					&:hover {
						color: $blue;
						background: $white;
					}
				}
			}
		}
	}
}

// home accommodations section
.home-accommodations-section {
	padding: 12vh 0 9vh 0;
	background: $grey;
	position: relative;

	&::after {
		content: "";
		width: 100%;
		height: 6vh;
		position: absolute;
		bottom: -1px;
		left: 0;
		background: url("/images/wave-blue-dark-bottom.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
	}
	.container {
		&.first {
			text-align: center;
			.subtitle {
				color: $blue;
			}
		}
		&.second {
			margin: 5vh auto;
			.grid-items {
				.item {
					max-width: 14% !important;
					flex-basis: 14% !important;
					@include media-breakpoint-down(lg) {
						max-width: 50% !important;
						flex-basis: 50% !important;
					}
					.card {
						background: $green;
						border-radius: 16px;
						border: 0;
						box-shadow: 0px 0px 10px 0px rgba(#000, 0.25);
						min-height: 180px;
						max-height: 180px;
						overflow: hidden;

						.card-image {
							margin-top: 2rem;
							i {
								color: $white;
								font-size: 36px;
								font-style: normal;
								font-weight: 200;
								line-height: normal;
							}
						}
						.card-body {
							.card-caption {
								display: flex;
								flex-flow: column;
								justify-content: flex-end;
								.card-subtitle,
								.card-description {
									display: none;
									opacity: 0;
								}
								.card-title {
									color: $blue-dark;
									text-align: center;
									font-family: $font-family-secondary;
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: normal;
								}
							}
						}
					}
					&:hover .card {
						transform: rotate3d(1, 1, 1, 360deg);
						box-shadow: 0px 0px 20px 0px rgba(#000, 0.15);
						transition: 1s ease;
						background: $green-dark;

						.card-title,
						.card-image {
							display: none;
						}

						.card-description,
						.card-subtitle {
							display: block !important;
							color: $white;
							opacity: 1 !important;
							transition: 0.35s ease;
							transition-delay: 0.35s;
							@starting-style {
								opacity: 0 !important;
								display: block !important;
							}
						}

						.card-subtitle {
							font-size: 16px;
							font-family: $font-family-secondary;
						}

						.card-description {
							font-size: 12px;
							max-height: 3.5rem;
							overflow: hidden;
						}

						.card-caption {
							justify-content: center;
						}
					}
				}
			}
		}
		&.third {
			div.read-more {
				text-align: center;

				a.read-more {
					margin: auto;
					@extend .btn, .btn-secondary;
				}
			}
		}
	}
}

// Home surroundingssection
.home-surroundings-section {
	background: url("/images/dot-blue-light.svg");
	background-repeat: no-repeat;
	background-size: 75%;
	background-position: center center;
	position: relative;

	&::after {
		content: "";
		width: 100%;
		height: 6vh;
		position: absolute;
		bottom: calc(-6vh + 1px);
		left: 0;
		background: url("/images/wave-blue-dark-bottom-two.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: top;
		z-index: 800;
	}

	.container {
		&.first {
			text-align: center;
			margin-bottom: 9vh;

			.title {
				color: $white !important;
			}

			.subtitle {
				color: $blue !important;
			}
		}

		&.second {
			.grid {
				.card {
					@extend .default-card;
				}
			}
		}

		&.third {
			margin-top: 6vh;
			div.read-more {
				text-align: center;

				a.read-more {
					margin: auto;
					@extend .btn, .btn-secondary;
				}
			}
		}
	}
}

// home banner section
.home-banner-section {
	position: relative;

	.owl-carousel {
		position: relative;
		.owl-dots,
		.owl-nav,
		.owl-caption {
			display: none;
		}
		.owl-item {
			height: 80vh;
		}
	}
	.container.hero {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		z-index: 800;
		.wysiwyg.hero {
			border-radius: 16px;
			border: 4px solid #fff;
			background: $blue;
			box-shadow: 0px 0px 10px 0px rgba(#000, 0.25);
			width: 380px;
			max-width: 100%;
			padding: 2rem 1.3rem;
			margin-left: auto;
			position: relative;
			&::before {
				content: "\f0a7";
				font-family: $font-awesome;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 44px;
				aspect-ratio: 1/1;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $white;
				color: $blue;
				font-size: 20px;
				font-style: normal;
				font-weight: 900;
			}
			.btn {
				width: 100%;
			}
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			text-align: center;
			.wysiwyg {
				max-width: 750px;
				margin: auto;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
				color: $green;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	// margin-top: calc(-6vh + 30px);
	// margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
