div.fabs {
	position: absolute;
	top: 25vh;
	right: 0;
	transition: 1.5s ease-in-out;
	z-index: 900;
	ul {
		display: flex !important;
		flex-flow: column;
		gap: 2.5rem;
		li {
			writing-mode: vertical-lr;
			text-orientation: mixed;
			display: flex;
			background: $blue-dark;
			color: $white;
			transform: scaleY(-1) scaleX(-1);
			border-radius: 0px 6px 6px 0px;
			border: 1px solid #fff;
			background: #7cba3c;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			a {
				display: flex;
				flex-flow: row-reverse;
				gap: 1rem;
				color: $white;
				padding: 30px 10px 30px 10px;
				text-decoration: none;
				font-family: $font-family-button;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				@include media-breakpoint-down(lg) {
					padding: 20px 7px 20px 7px;
					font-size: 12px;
				}
				i {
					transform: scaleY(-1) scaleX(-1) translate(25%, -50%);
					position: absolute;
					bottom: 0;
					width: 30px;
					aspect-ratio: 1/1;
					border-radius: 50%;
					background: $white;
					color: $green;
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: 0.35s ease;
					@include media-breakpoint-down(lg) {
						width: 20px;
					}
				}
				&:hover {
					i {
						transform: scaleY(-1) scaleX(-1) translate(25%, -50%)
							rotate(360deg);
						transition: 0.35s ease;
					}
				}
			}
		}
	}
}
