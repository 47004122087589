// footer
&.home .footer {
	margin-top: initial;
	.footer-main {
		&::after {
			z-index: 700;
		}
	}
}
.footer {
	margin-top: 12vh;
	background: $grey;
	.footer-main {
		position: relative;
		padding: 6vh 15px;
		&::after {
			content: "";
			width: 100%;
			height: 6vh;
			position: absolute;
			top: calc(-6vh + 1px);
			left: 0;
			background: url("/images/wave-white-bottom.svg");
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: bottom;
		}
		.footer-text {
			em {
				color: $blue;
				font-family: $font-family-secondary;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		.vocabulary-term-list.contact {
			display: flex;
			flex-flow: column;
			gap: 0.25rem;
			li a {
				display: flex;
				max-width: fit-content;
				gap: 0.5rem;
				color: $blue-dark;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-decoration: none;
				font-family: $font-family-button;
				i {
					font-size: 20px;
					font-style: normal;
					font-weight: 900;
					line-height: normal;
					transition: 0.35s ease;
				}
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
			}
		}
		.vocabulary-term-list.social {
			display: flex;
			gap: 0.5rem;
			margin-top: 1rem;
			li a {
				.list-item-title {
					display: none;
				}
				width: 44px;
				height: 44px;
				background: $blue-dark;
				color: $white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				i {
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
				&:hover {
					background: $green;
				}
			}
		}
	}
	.footer-socket {
		background: $white;
		padding: 0.5rem 15px;
		.footer-socket .list {
			display: flex;
			gap: 1.5rem;
			justify-content: flex-end;
			margin-left: auto;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				justify-content: flex-end;
				gap: 0.5rem;
				li {
					text-align: right;
				}
			}
			a {
				color: #000;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		div.back-to-top {
			position: absolute;
			left: 0;
			bottom: -20px;
			a {
				display: block;
				width: 40px;
				aspect-ratio: 1/1;
				font-size: 36px;
				text-decoration: none;
				color: #0d3c60;
				&::after {
					content: "\f357";
					font-family: $font-awesome;
					font-size: 36px;
					font-style: normal;
					font-weight: 900;
					line-height: normal;
				}
			}
		}
	}
}
