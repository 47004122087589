&:not(.home) {
	main {
		background: url("/images/dot-blue-dark.svg");
		background-repeat: no-repeat;
		background-size: 90%;
		background-position: center left -300px;
	}
}
// container
.container {
	@media (min-width: 1280px) {
		max-width: 1210px;
	}
	@include media-breakpoint-down(lg) {
		padding: 0 1.5rem;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 16px;
	background: $white;
	box-shadow: 0px 0px 10px 0px rgba(#000, 0.25);
	padding: 5px;
	div.card-image {
		border-radius: 16px 16px 0 0;
		position: relative;
	}
	.card-body {
		position: relative;
		&::after {
			content: "\f061";
			font-family: $font-awesome;
			width: 44px;
			height: 44px;
			background: $blue;
			border: 3px solid $white;
			font-size: 32px;
			font-style: normal;
			font-weight: 900;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			aspect-ratio: 1/1;
			border-radius: 50%;
			transition: 0.35s ease;
		}
	}
	.card-caption {
		padding: 1rem 0;
		.card-title {
			color: $black;
			font-family: $font-family-primary;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 0.35rem;
		}
		.card-subtitle {
			@extend .subtitle;
			color: $blue;
		}
	}
	&:hover .card-body:after {
		scale: 1.1;
		transition: 0.35s ease;
	}
	.card-btn {
		background: transparent;
		border: none !important;
		outline: none !important;
		padding: 0;
		color: $blue-dark;
		&::after {
			content: "\f054";
			margin-left: 0.5rem;
			font-family: $font-awesome;
			transition: 0.35s ease;
		}
		&:hover {
			color: $green;
			&::after {
				margin-left: 1rem;
				transition: 0.35s ease;
			}
		}
		&:active,
		&:focus {
			border: none !important;
			outline: none !important;
			box-shadow: none !important;
		}
	}
}

// subtitle
.subtitle {
	font-family: $font-family-secondary !important;
	font-size: $h5-font-size;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

// main mobile

&.w3-booking-widget-fullscreen-active:not(.home) .main {
	@include media-breakpoint-down(lg) {
		z-index: unset;
		position: unset;
	}
}
