// mini-sab
&.home .mini-sab {
	background: $blue;
}
.mini-sab {
	background: transparent;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 890;
			width: 100%;
			padding: 0 !important;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -8vh;
			}
			@include media-breakpoint-down(lg) {
				margin-top: -6vh;
				.column {
					padding: 0 !important;
				}
				.filters-fullscreen-toggle {
					padding: 8px;
				}
			}
		}
	}
}
